/*
https://coolors.co/ef476f-ffd166-83d483-118ab2-0a4c63-020e13-192428-414a4e-525a5e
*/

html, body {
    max-width: 100%;
    overflow-x: hidden;
}
a {
    color:#4a69bd;
}

.cHeader {
    background-color: lightblue;
}

.cTextCenter {
    text-align:center
}

.cBadges {
    text-align: center;
    
    margin: 30px;
}
.cCVDetails {
    display: "inline";
    font-size: small;
    border-radius: 0.2em
}

.cWelcome {
    font-size: 2.5em;
    font-weight:500;
    margin-bottom:0.5rem;
    line-height:1.2;
}

.cTWHeading {
    font-size: 2em;
}
.cCardTitle {
    font-size:1.25em;
}

.cContactHeading {
    font-size: 1.25em;
}
.cCVsub {
    font-size: 1.25em;
}
.bg-dark {
    background-color: #020E13;
}
/*
.cCenter {
    background-color: lawngreen;
}
*/
.cJumbotron {
    background-image: url('./images/computer-767776_1280.jpg');
    background-size: cover;
    min-height: 50vh;
}

.cNameDescription {
    color:#020E13;
    font-size: xx-large;
    font-weight: 400;
}
.Typewriter {
    display: inline;
}
.cTypewriter {
    white-space: nowrap;
    display: inline;
    color: #4a69bd;
}

.cTypeWriterMobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .cTypeWriterMobile {
    display: block;
  }
  
}
.cBadgeImage {
    width: 150px;
}    

.cBadgeImage:hover {
    width:250px;
}

.cCard {
    border: 0px;
}
.cBtn:hover {
background-color:rgb(65, 63, 63) !important;
color:white;


}
.cAuthor {
    font-weight: 300;
    font-style:italic;
}

.cH2Top {
    color: #4a69bd;
}
.cBtn {
    /*background-color:#118ab2 !important;*/
    background-color:#4a69bd;
    border-color: black;
    color:white;
    
}

.cBtnSubmitted {
    color: #83D483;
    float: right;
    font-weight: 600;
}

.cthProjects {
    text-align: center;
}

.cFooter {
    
    margin-bottom: 0px;
    padding-bottom: 0px;
    color: #525A5E;
    background-color: #020E13;
    
    padding: 10px 0px 10px ;
    min-height: 20px;   
}

.cDatenschutz {
    color: #525A5E;
    background-color: #020E13;
    font-size: smaller;
    margin-left:10px;
    padding: 10px 0px 10px ;
    min-height: 20px;   
}

.cCopyright {
    color: #525A5E;
    background-color: #020E13;
    font-size: smaller;
    margin-left:10px;
    margin-right:10px;
    padding: 10px 0px 10px ;
    min-height: 20px;   
    text-align: right;
}

p.cSorry {
    color: red;
    text-align: center;
    font-weight: bolder;
    padding:20px;
}

/*CV*/
.cCVTime {
    text-align:center;
    width: 30%;
}
.cCVTitle {
    width: 70%;
}

/*projekte*/
.cPTime {
    text-align:center;
    width: 15%;
}
.cPTitle {
    width: 50%;
}
.cPTechnik {
    width: 35%;
}
/* PADDING
*/
.cPadding10 { 
    margin:10px; 
    padding:10px; 
}
.cPaddingContact { 
     
    padding-top:35px; 
    padding-left:20px;
}

a.active.cFooterLinks {
    text-decoration: none;
    color: #525A5E !important;
}

.cTestimonal {
    margin-left:20%;
    margin-right:20%;
    text-align: center;
    font-weight: 300;
}
.cTestimonalFooter {
    text-align: center;
    font-style: italic;
    font-weight: 500;
}